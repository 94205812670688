@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn {
    @apply py-2 px-4 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75;
  }
  .btn-secondary {
    @apply py-2 px-4 bg-white text-blue-500 font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75;
  }
}


/* for checkbox */
input:checked + svg {
  display: block;
}


/* loader - https://projects.lukehaas.me/css-loaders/ */
.loader,
.loader:before,
.loader:after {
background: #ffffff;
-webkit-animation: load1 1s infinite ease-in-out;
animation: load1 1s infinite ease-in-out;
width: 1em;
height: 4em;
}
.loader {
color: #ffffff;
text-indent: -9999em;
margin: 88px auto;
position: relative;
font-size: 11px;
-webkit-transform: translateZ(0);
-ms-transform: translateZ(0);
transform: translateZ(0);
-webkit-animation-delay: -0.16s;
animation-delay: -0.16s;
}
.loader:before,
.loader:after {
position: absolute;
top: 0;
content: '';
}
.loader:before {
left: -1.5em;
-webkit-animation-delay: -0.32s;
animation-delay: -0.32s;
}
.loader:after {
left: 1.5em;
}
@-webkit-keyframes load1 {
0%,
80%,
100% {
  box-shadow: 0 0;
  height: 4em;
}
40% {
  box-shadow: 0 -2em;
  height: 5em;
}
}
@keyframes load1 {
0%,
80%,
100% {
  box-shadow: 0 0;
  height: 4em;
}
40% {
  box-shadow: 0 -2em;
  height: 5em;
}
}

.PhoneInput input {
border: none;
}

.react-datepicker__time-list-item--disabled {
display: none;
}

.loadingGradient {
background: rgb(9, 9, 121);
background: radial-gradient(circle, rgba(0, 0, 0, .4) 10%, rgba(0, 0, 0, 0) 100%);
transition: all 0.2 ease-in-out;
}

#payment-element {
  margin-bottom: 1rem;
}

#payment-message {
  background-color: red;
  color: #eee;
}

/* //////////// */


/* stripe stuff vvv */

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}


.spinner {
    width: 32px;
    height: 32px;
    border: 5px solid #000;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    transform: translateZ(0);
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


.admin-loader {
  width: 100%;
  height: 5px;
  display: inline-block;
  position: relative;
  background: rgba(0, 0, 0, 0.3);
  overflow: hidden;
}
.admin-loader::after {
  content: '';
  width: 192px;
  height: 5px;
  background: #FFF;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  animation: admin-animloader 2s linear infinite;
}

@keyframes admin-animloader {
  0% {
    left: 0;
    transform: translateX(-100%);
  }
  100% {
    left: 100%;
    transform: translateX(0%);
  }
}
